
// Breakpoints
$maxPhone: 1025px;
$max-phone: 1025px;

// Colors

$color-primary: #5669FF;
$color-secondary: #201A3C;
$color-ternary: #EAEDF6;
$color-warn: #d68578;

$background-color-1: rgb(234,235,255);
$background-color-2: rgba(234,235,255, 0.55);
$background-color-3: rgba(234,235,255, 0.35);

$color-red: #f44336;
$color-green: #83d999;
$color-yellow: #fac561;
$color-blue: #80cdda;

$color-text-1: #565656;
$color-text-2: #808285;
$color-text-3: #BCBEC0;
$color-text-4: #E6E7E8;
$color-text-5: #F1F2F3;

$color-light-primary: #B5CDFE;
$color-white: #FFFFFF;
$color-black: #000000;
$color-gray: #6e6e6e;
$color-light-gray: #cacaca;

// Fonts

$font-family: 'Montserrat', sans-serif;
