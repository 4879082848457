@use '@angular/material' as mat;
@use 'vars' as vars;
@use './theme-colors' as theme;

:root {
  --primary-color: #106DAF;
  --secondary-color: #73CCDB;
  --tertiary-color: #47484A;
  --neutral-color: #8C8C8C;
  --light-color: #E6EEF5;

  --background-color: #F7F7F7;
  --content-background-color: #FFFFFF;
  --hover-color: #e5e5e5;

  --error-color: #E63946;
  --success-color: #28A745;
  --warning-color: #FFB300;
  --mat-sys-surface-container: #FFFFFF;
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Montserrat', 'Arial', sans-serif;
  margin: 0;
  height: 100dvh;

  body { height: 100%; }

  a { color: var(--primary-color); }
  ul, ol { margin: 0; padding: 0; list-style: none; }
  h1, h2, h3, h4 { font-weight: 600; margin: 0; }
  p { margin: 0; }

  @include mat.theme((
    color: (
      primary: theme.$primary-palette,
      tertiary: theme.$tertiary-palette,
    ),
    typography: 'Montserrat',
  ));

  @include mat.form-field-density(-3);

  @include mat.form-field-overrides((
    outlined-container-shape: 8px,
    subscript-text-size: .75rem,
    subscript-text-line-height: .75rem,
    container-text-line-height: .75rem,
    outlined-label-text-color: var(--neutral-color),
    outlined-outline-color: var(--neutral-color),
  ));

  @include mat.button-overrides((
    filled-container-shape: 8px,
    filled-container-height: 36px,
    outlined-container-shape: 8px,
    outlined-container-height: 36px,
  ));

  @include mat.card-overrides((
    elevated-container-elevation: 0,
    elevated-container-color: var(--content-background-color),
    outlined-container-color: var(--content-background-color),
  ));

  @include mat.snack-bar-overrides((
    container-shape: 8px,
  ));

  @include mat.sidenav-overrides((
    container-background-color: var(--content-background-color),
    container-shape: 0px,
  ));

  @include mat.toolbar-overrides((
    container-background-color: var(--content-background-color),
  ));

  .mat-mdc-form-field-bottom-align::before {
    height: 13px;
  }

  body {
    margin: 0;

    .mat-mdc-form-field-infix {
      display: flex;
    }
  }

  &[dir=rtl] {
    .mat-icon {
      direction: rtl;
    }
  }

  mat-snack-bar-container {
    margin-top: 70px !important;
  }

  mat-card {
    overflow: hidden;
  }
}


// LEGACY

.container {
  max-width: clamp(1200px, 1200px, 100%);

  & > form { max-width: 100%; }
}

.d-none { display: none !important; }
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-block {
  display: block;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

$text-alignments: left, center, right;

@each $value in $text-alignments {
  .text-#{$value} {
    text-align: $value;
  }
}

$alignments: start, center, end, stretch, space-between;

@each $value in $alignments {
  .align-#{$value}, .align-items-#{$value} {
    align-items: $value;
  }
  .justify-content-#{$value} {
    justify-content: $value;
    @if $value == start {
      justify-content: flex-start;
    }
    @if $value == end {
      justify-content: flex-end;
    }
  }
}

@for $i from 1 through 20 {
  .w-#{$i*5} {
    width: $i * 5%;
  }
  .h-#{$i*5} {
    height: $i * 5%;
  }
}


@for $value from 0 through 64 {
  .gap-#{$value} {
    gap: $value + px;
  }

  .p-#{$value} {
    padding: $value + px !important;
  }
  .pt-#{$value} {
    padding-top: $value + px;
  }
  .pr-#{$value} {
    padding-right: $value + px;
  }
  .pb-#{$value} {
    padding-bottom: $value + px;
  }
  .pl-#{$value} {
    padding-left: $value + px;
  }
  .px-#{$value} {
    padding-left: $value + px;
    padding-right: $value + px;
  }
  .py-#{$value} {
    padding-top: $value + px;
    padding-bottom: $value + px;
  }

  .m-#{$value} {
    margin: $value + px;
  }
  .mt-#{$value} {
    margin-top: $value + px;
  }
  .mr-#{$value} {
    margin-right: $value + px;
  }
  .mb-#{$value} {
    margin-bottom: $value + px;
  }
  .ml-#{$value} {
    margin-left: $value + px;
  }
  .mx-#{$value} {
    margin-left: $value + px;
    margin-right: $value + px;
  }
  .my-#{$value} {
    margin-top: $value + px;
    margin-bottom: $value + px;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}


@for $value from 8 to 64 {
  .font-size-#{$value} {
    font-size: $value + px !important;
  }
}

$fontWeights: 100, 200, 300, 400, 500, 600, 700, 800, 900, bold;

@each $value in $fontWeights {
  .font-weight-#{$value} {
    font-weight: $value;
  }
}

$borderRadiuses: 1, 2, 3, 4, 5, 6, 8, 12, 16;

@each $value in $borderRadiuses {
  .b-#{$value} {
    border: 1px solid;
  }
}

.uppercase {
  text-transform: uppercase;
}

.no-wrap {
  text-wrap: nowrap;
}

.pointer {
  cursor: pointer;
  user-select: none;
}
.no-pointer-events {
  pointer-events: none;
}

.bg-primary {
  background-color: vars.$color-primary;
}
.bg-white {
  background-color: vars.$color-white;
}
.bg-black {
  background-color: vars.$color-black;
}
.bg-light-gray {
  background-color: vars.$color-light-gray;
}

.color-primary {
  color: vars.$color-primary;
}
.color-white {
  color: vars.$color-white;
}
.color-black {
  color: vars.$color-black;
}
.color-gray {
  color: vars.$color-gray;
}
.color-light-gray {
  color: vars.$color-light-gray;
}

.pointers-none {
  pointer-events: none;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
}

h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

a {
  color: #0095FF;
}

.p-absolute {
  position: absolute;
}
.p-relative {
  position: relative;
}
.p-fixed {
  position: fixed;
}
.p-sticky {
  position: sticky;
}

.color-warn {
  color: vars.$color-red;
}

html[dir=rtl] .mat-icon {
  direction: rtl;
}

mat-snack-bar-container {
  margin-top: 70px !important;
}
